import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton } from '@mui/material';
import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import CircularLoader from 'components/dog-loader/dog-lodar';
import {
  generateContractCRM,
  generateContractCGM,
  getCdaContractGenerateWarning
} from 'helpers/pdf-template/pdf-template-action-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import { useContext, useState } from 'react';
import { OpportunityEntity } from 'types/opportunity-entity';
import { PdfTemplateEntity } from 'types/pdf-template-types';

const GenerateContractConfirmationModal = ({
  pdfTemplate,
  opportunity
}: {
  pdfTemplate: PdfTemplateEntity;
  opportunity: OpportunityEntity;
}) => {
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );
  const [isLoading, setIsLoading] = useState(false);

  const { marketPreference } = useContext(OpportunityContext);

  const isCDAContract = (): boolean => {
    return (
      pdfTemplate.category_id === 'commission_instructions' &&
      pdfTemplate.document_subtype === 'cda'
    );
  };

  const isOpportunityInDiligencePeriod = (): boolean => {
    return [
      oppurtunityStatusList.closing_diligence_period,
      oppurtunityStatusList.closing_diligence_amended
    ].includes(opportunity.opportunity_status_c);
  };

  const shouldGenerateCadInDueDiligencePeriod = (): boolean => {
    return isCDAContract() && isOpportunityInDiligencePeriod();
  };

  const generateContract = async (
    pdf_template_id: string,
    opportunity_id: string,
    associate_with_opportunity: boolean
  ) => {
    setIsLoading(true);
    if (marketPreference.enable_generate_cgm_contract) {
      await generateContractCGM(
        pdf_template_id,
        opportunity_id,
        associate_with_opportunity
      );
    } else {
      await generateContractCRM(
        pdfTemplate.id!,
        opportunity_id,
        associate_with_opportunity
      );
    }
    setIsLoading(false);
  };

  const handleClick = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (shouldGenerateCadInDueDiligencePeriod()) {
      setConfirmModal({
        open: true,
        text: getCdaContractGenerateWarning(opportunity?.opportunity_status_c),
        title: fieldLabel.areYouSure,
        proceed: () => {
          generateContract(pdfTemplate.id!, opportunity!.id, true);
          setConfirmModal(initialConfirmModalState);
        },
        cancel: () => {
          setConfirmModal({ ...confirmModal, open: false });
        }
      });
    } else if (isCDAContract()) {
      generateContract(pdfTemplate.id!, opportunity.id, true);
    } else {
      generateContract(pdfTemplate.id!, opportunity.id, false);
    }
  };

  if (isLoading) return <CircularLoader />;

  return (
    <>
      <IconButton aria-label="" onClick={handleClick}>
        <FileDownloadIcon color="info" />
      </IconButton>
      {confirmModal.open && (
        <ConfirmBox key={pdfTemplate.id} {...confirmModal} />
      )}
    </>
  );
};

export default GenerateContractConfirmationModal;
