import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  FormControl,
  Autocomplete,
  Chip,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FeaturedFlag } from 'types/admin-config';
import userService from 'services/user-service';
import accountService from 'services/account-service';
import marketPreferenceService from 'services/market-preference-service';
import { CreateResponse, GetListResponse } from 'types/api-response-types';
import { toastError, toastSuccess } from 'event/toast-event';
import { isEmpty } from 'helpers/misc-helper';
import featuresService from 'services/feature-service';
import categoriesOptions from 'assets/constants/featured-categories';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { ErrorValidation } from 'types/error-types';
import { validateFeaturedFlag } from 'helpers/validation/featured';
import ValidationError from 'components/errors/validation-error';
import pdfTemplateService from 'services/pdf-template-service';

const serviceOptions: {
  [key: string]: (val: string) => Promise<GetListResponse<any>>;
} = {
  user: userService.getList,
  account: accountService.getList,
  market_preference: marketPreferenceService.getList,
  contract: pdfTemplateService.getList
};

const RowCard = ({
  data,
  onSave,
  onCancel,
  checkDuplicate = () => false,
  handleDelete = () => ({})
}: {
  data: FeaturedFlag;
  onSave: (val: FeaturedFlag) => void;
  onCancel: () => void;
  checkDuplicate?: (val: FeaturedFlag) => boolean;
  handleDelete?: (val: FeaturedFlag) => void;
}) => {
  const isNew = !data.id;

  const [flag, setFlag] = useState(data);

  const [targetOptions, setTargetOptions] = useState<
    { id: string; name: string }[]
  >([]);

  const [isEdit, setIsEdit] = useState(false);
  const [validations, setValidations] = useState<ErrorValidation>({});

  const isEditable = isNew || isEdit;

  const loadOptions = async (model: string) => {
    setTargetOptions([]);
    if (model) {
      let query = '?page=1&per_page=2000';
      if (model === 'user') {
        query += '&filter[status]=Active';
      }
      const result = await serviceOptions[model](query);
      if (result.isError) {
        toastError(result.errorMessage.message);
        return;
      }

      let data;
      if (model == 'user') {
        data = result.data.data as {
          id: string;
          first_name: string;
          last_name: string;
          email: string;
        }[];

        data = data.map((x) => ({
          id: x.id,
          name: `${x.first_name} ${x.last_name}(${x.email})`
        }));
      } else {
        data = result.data.data as { id: string; name: string }[];
      }

      setTargetOptions(data.filter((x) => !isEmpty(x.name)));
    }
  };

  const handleSave = async () => {
    const { errors, status } = validateFeaturedFlag(flag);

    setValidations(errors);
    if (!status) return;

    if (isNew && checkDuplicate(flag)) {
      toastError(
        'A feature flag with the same name and category already exists.'
      );
      return;
    }

    if (flag.targets.length === 0) {
      toastError(`Targets can not be blank`);
      return;
    }

    const payload = new FormData();

    payload.append('name', flag.name);
    payload.append('target_model', flag.target_model);

    flag.targets.forEach((target) => {
      payload.append('target_ids[]', target.id);
    });

    let result: CreateResponse<FeaturedFlag>;
    result = await featuresService.create(payload);

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    toastSuccess('Flag Saved Successfully');
    setIsEdit(false);
    onSave(flag);
  };

  const handleCancel = () => {
    setIsEdit(false);
    if (isNew) {
      onCancel();
    }
    setFlag(data);
  };

  useEffect(() => {
    if (isEditable) {
      loadOptions(flag.target_model);
    }
  }, [flag.target_model]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        padding: isEditable ? 2 : 1,
        flexWrap: 'wrap',
        borderRadius: 2,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ddd',
        backgroundColor: '#fff',
        marginBottom: 2,
        marginX: 1
      }}
    >
      {isNew ? (
        <>
          <TextField
            label="Flag Name"
            variant="outlined"
            value={flag.name}
            sx={{ width: '25%' }}
            onChange={(e) => {
              setFlag((prev) => ({ ...prev, name: e.target.value }));
            }}
            helperText={<ValidationError data={validations?.name} />}
          />
        </>
      ) : (
        <Typography variant="h6" mb={2} sx={{ width: '25%' }}>
          {data.name}
        </Typography>
      )}

      {isNew ? (
        <FormControl sx={{ width: '25%' }}>
          <InputLabel id="users-multiselect-label">Category</InputLabel>
          <Select
            labelId="users-multiselect-label"
            value={flag.target_model}
            onChange={(e) => {
              setFlag((prev) => ({
                ...prev,
                target_model: e.target.value,
                targets: []
              }));
            }}
            renderValue={(selected) => categoriesOptions[selected]}
            label="Category"
          >
            {getObjectEntriesAsArray(categoriesOptions).map((user) => (
              <MenuItem key={user.value} value={user.value}>
                <ListItemText primary={user.label} />
              </MenuItem>
            ))}
          </Select>
          {validations?.target_model &&
            validations?.target_model.length > 0 && (
              <ValidationError data={validations['target_model']} />
            )}
        </FormControl>
      ) : (
        <Typography variant="h6" mb={2} sx={{ width: '25%' }}>
          {categoriesOptions[flag.target_model]}
        </Typography>
      )}

      <FormControl sx={{ flex: 2 }}>
        <Autocomplete
          multiple
          options={targetOptions}
          value={flag.targets}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, selectedUsers) => {
            setFlag((prev) => ({ ...prev, targets: selectedUsers }));
          }}
          renderTags={(selectedUsers, getTagProps) =>
            selectedUsers.map((user, idx) => (
              <Chip
                label={user.name}
                {...getTagProps({ index: idx })}
                key={user.id}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={categoriesOptions[flag.target_model]}
              variant="outlined"
            />
          )}
          readOnly={!isEditable}
        />
        {validations?.targets && validations?.targets.length > 0 && (
          <ValidationError data={validations['targets']} />
        )}
      </FormControl>

      {!isNew && !isEditable && (
        <>
          <IconButton
            onClick={() => {
              setIsEdit(true);
              loadOptions(data.target_model);
            }}
            sx={{ padding: 0 }}
            color="info"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(data)}
            sx={{ padding: 0 }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </>
      )}

      {isEditable && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginLeft: 2 }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCancel}
            sx={{ marginLeft: 2 }}
          >
            Cancel
          </Button>
        </>
      )}
    </Box>
  );
};

export default RowCard;
